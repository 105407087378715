import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { isBlogNotFound } from ':lib/_i18n';

import { LOCALES } from '../../locales';

const isInternalLink = (href) => {
  return (
    href && /^(\/|\w)/.test(href) && !/rippling\.com|^http|^https/.test(href)
  );
};
const getHrefPathname = (href) => {
  return href.replace(/(.*?)rippling\.com/, '');
};
const shouldUseLocale = (href, locale) => {
  if (!href) {
    return true;
  }

  if (href === '/app-shop') {
    return false;
  }

  return !isBlogNotFound(getHrefPathname(href), locale);
};

const LinkHelper = (props) => {
  const {
    anchorProps,
    children,
    className,
    data,
    href,
    id,
    locale,
    onClick,
    onTransitionEnd,
    rel,
    simulateClick,
    target,
  } = props;

  const router = useRouter();
  const cookies = new Cookies();
  const cookiedSiteLocale = cookies.get('NEXT_LOCALE');

  // we dont want to accidentally opt users in/out without end-to-end middleware runs
  const optPaths = cookies.get('opt_paths');
  const enablePrefetch = optPaths && !optPaths.includes(href);
  if (data && onClick) {
    data.onClick = onClick;
  }
  const localeToUse = shouldUseLocale(href, locale)
    ? locale || cookiedSiteLocale
    : LOCALES.EN_US;

  if (simulateClick) {
    let press;

    return (
      <div
        className={className}
        onMouseDown={() => {
          press = Date.now();
        }}
        onMouseUp={() => {
          if (Date.now() - press < 200) {
            router.push(href);
          }
        }}
        onTransitionEnd={onTransitionEnd}
        {...anchorProps}
        {...data}
      >
        {children}
      </div>
    );
  } else if (isInternalLink(href)) {
    return (
      <Link
        className={className}
        href={href}
        id={id}
        locale={localeToUse}
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
        prefetch={enablePrefetch}
        target={target}
        {...anchorProps}
        {...data}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={className}
        href={href}
        id={id}
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
        rel={rel ?? 'noopener noreferrer'}
        target={target ?? '_blank'}
        {...anchorProps}
        {...data}
      >
        {children}
      </a>
    );
  }
};

export default LinkHelper;
